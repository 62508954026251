<template>
  <f7-popup class="productselect-popup" :opened="open" :tablet-fullscreen="false" :backdrop="true" @popup:opened="openPopup" @popup:closed="closePopup">
    <f7-page infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation("LBL_SELECT_PRODUCT") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar v-model:value="searchValue" :disable-button="false" :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')" :clear-button="true"></f7-searchbar>

      <div class="product-container">
        <template v-for="(product, ind) in productList" :key="'ps_' + ind">
          <ProductListCardComponent :data="product" type="select" @selected="selectProduct" :isShop="true"/>
        </template>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { ref, onMounted, inject, watch, defineAsyncComponent } from "vue";
import { f7 } from "framework7-vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";

const ProductListCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list-card" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue"));

export default {
  name: "ProductSelectionPopUpComponent",
  components: {
    ProductListCardComponent,
  },
  props: {
    open: Boolean,
    kol : {
      type : Boolean,
      default : false
    }
  },
  emits: ["opened", "closed", "selected"],
  setup(props, { emit }) {
    const store = useStore();
   
    const $t = inject("$translation");

    let size = 15;
    let page = 1;
    let lastPage = 1;

    const productList = ref([]);
    const showPreloader = ref(false);
    const searchValue = ref("");

    const getProductList = async () => {
      let ret = await get("/mobile/product/public/list", { KOL : (props.kol) ? 1 : 0, LanguageCode: $t.getLanguage(), page, size, SearchField: "MULTIPLE", SearchType: "LIKE", SearchValue: searchValue.value });

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          productList.value.push(item);
        }

        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage > page ? getProductList() : false;
    };

    onMounted(getProductList);

    watch(searchValue, (newValue, oldValue) => {
      productList.value = [];
      page = 1;
      getProductList();
    });

    const openPopup = () => {
      emit("opened", true);
    };

    const closePopup = () => {
      emit("closed", true);
    };

    const selectProduct = (data) => {
      f7.popup.close();
      emit("selected", data);
    };

    return {
      openPopup,
      closePopup,
      productList,
      selectProduct,
      loadMore,
      showPreloader,
      searchValue,
    };
  },
};
</script>

<style scoped>
.productselect-popup.popup .navbar .title {
  margin-left: 15px;
}
.productselect-popup .product-container {
  width: calc(100% - 40px);
  margin: 20px auto;
}
</style>
